<template>
  <div class="prize-saver-privacy-policy">

    <h1>Prize Draw - Privacy Notice</h1>
    <h5>Last Updated: 09/11/2021</h5>
    <p>This Privacy Notice describes how Cash Box Credit Union and Association of British Credit Unions Ltd (ABCUL) (together, "<strong>we</strong>", "<strong>us</strong>") collect and process personal information of PrizeSaver Account Customers in relation to the Prize Draw scheme (the "<strong>Prize Draw</strong>").&nbsp;</p>
    <p>Further information about how Cash Box Credit Union processes personal data in relation to providing the PrizeSaver Account more generally is available in the Credit Union's Privacy Policy available at <a href="https://www.cashbox.org.uk/privacy-policy" target="_blank">https://www.cashbox.org.uk/privacy-policy/</a></p>
    <h4>Controller of personal information</h4>
    <p>Cash Box Credit Union whose registered office is at Denton Town Hall, Market Street, Denton, M34 2AP and ABCUL whose address is Holyoake House, Manchester, are both data controllers of personal information collected and processed in relation to the Prize Draw.</p>
    <h4>What personal information do we collect?</h4>
    <p>Here are the types of information we collect about you in order to provide the Prize Draws, to provide marketing about the Prize Draws and to evaluate the effectiveness of the Prize Draws:</p>
    <ul>
      <li>your name and contact information, including your email address and telephone number</li>
      <li>information about your PrizeSaver account. This includes information about the accounts you hold with Cash Box the transactions on your account and the balance of your account</li>
      <li>information to enable us to undertake customer due diligence checks on you including: Proof of identity and proof of address</li>
      <li>socio-demographic information (such as income, savings levels etc) which we review on an anonymised basis; and</li>
      <li>any information you provide about how you were made aware of the PrizeSaver account.</li>
    </ul>
    <h4>For what purposes do we process personal information?</h4>
    <p>We process personal information for the following purposes:</p>
    <ul>
      <li><strong>Eligibility</strong>. We use your personal information to consider your eligibility for a Prize Draw, to award a winner and to facilitate the payment of Prizes.</li>
      <li><strong>Communicate with you.</strong> We use personal information to communicate with you in relation to the Prize Draws.</li>
      <li><strong>To deal with your enquiries and requests.</strong></li>
      <li><strong>For marketing purposes.</strong> We use personal information to develop, manage and conduct advertising and marketing campaigns, promotions and offers.</li>
      <li><strong>Evaluation of the Prize Draws.</strong> We will also use personal information to evaluate the success and effective operation of the Prize Draws. We may contact you directly for the purpose of conducting an evaluation of the Prize Draws.</li>
      <li><strong>Fraud Prevention and AML checks.</strong> We process personal information to prevent and detect fraud and to run anti-money laundering checks to ensure that Prizes are not awarded to individuals who have made fraudulent entries or do not comply with our anti-money laundering checks.</li>
      <li><strong>Comply with legal obligations.</strong> In certain cases, we have a legal obligation to collect and process personal information.</li>
    </ul>
    <p>We must have a lawful basis to process personal information, which in most cases will be one of the following:</p>
    <ul>
      <li>to fulfil Cash Box Credit Union's contractual obligations to you, including to operate and provide the Prize Draw</li>
      <li>to comply with the respective legal obligations of Cash Box Credit Union and the organisers, for example, obtaining proof of your identity to enable us to meet our anti-money laundering obligations</li>
      <li>in relation to data processing by ABCUL, to perform a specific task in the public interest that is set out in law</li>
      <li>we may obtain your consent to collect and use certain types of personal information when we are required to do so by law (for example, in relation to Cash Box Credit Union's direct marketing activities). If we ask for your consent to process your personal information, you may withdraw your consent at any time by contacting us using the details at the end of this privacy notice, or</li>
      <li>to meet the legitimate interests of Cash Box Credit Union and ABCUL, for example to understand how you use your PrizeSaver account and to enable us to derive knowledge from that to enable us to develop new products and services.</li>
    </ul>
    <p>Where we rely on our legitimate business interests, we have undertaken an assessment where we have balanced individuals' rights against ours to ensure that our interest is not overridden by the interests which individuals have to protect personal information.</p>
    <h4>Information sharing</h4>
    <p>We may share personal information for certain purposes and with the entities described in this section:</p>
    <ul>
      <li><strong>Service providers</strong>: We will share personal information with our service providers to perform functions on our behalf, including carrying out the Prize Draw, performing marketing services, evaluating the Prize Draw scheme and any other business operations for us.</li>
      <li><strong>Business transfers</strong>: We may share personal information to the extent reasonably necessary to proceed with the consideration, negotiation, or completion of a merger, reorganization, or acquisition of our business, or a sale, liquidation, or transfer of some or all of our assets.</li>
      <li><strong>As required by law</strong>: We may share personal information to law enforcement agencies, courts, regulators, government authorities or other third parties. We may share personal information with these parties where we believe this is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights or the rights of any third party.&nbsp;</li>
    </ul>
    <h4>Transfers out of the European Economic Area</h4>
    <p>Whenever we transfer personal information to countries outside of the European Economic Area in the course of sharing information as set out above, we will put in place appropriate safeguards (such as standard contractual clauses) in accordance with applicable laws on data protection. For more information on the appropriate safeguards in place or to obtain a copy, please contact us using the contact information below.&nbsp;</p>
    <h4>What rights do I have?</h4>
    <p>Subject to applicable law, you have the following rights</p>
    <ul>
      <li>to request information about how your personal information is processed and to request a copy of the personal information</li>
      <li>to request that any inaccuracies in your personal information are rectified</li>
      <li>to request that your personal information is deleted</li>
      <li>in certain circumstances, to request that the processing of your personal information is restricted</li>
      <li>to object to the processing of your personal information; and</li>
      <li>to data portability, which allows your data to be copied or transferred from one IT environment to another.</li>
    </ul>
    <p>If you wish to do any of these things, please contact Cash Box Credit Union at <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a></p>
    <h4>How long do we keep personal information?</h4>
    <p>We will keep your personal information for as long as we have a relationship with you and for a period of time thereafter to enable us to:</p>
    <ul>
      <li>maintain business records for analysis and/or audit purposes</li>
      <li>comply with record retention requirements under the law</li>
      <li>defend or bring any existing or potential legal claims</li>
      <li>deal with any complaints regarding the services, and</li>
      <li><strong>any other purposes for which personal information will be retained.</strong></li>
    </ul>
    <p>We will delete your personal information when it is no longer required for these purposes. If there is any information that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further processing or use of the data.</p>
    <h4>Contacts, notices and revisions</h4>
    <p>If you have any questions about this Privacy Notice, please contact Cash Box Credit Union at cashbox.org.uk.&nbsp; We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy.&nbsp; If, however, you believe that we have not been able to assist with your complaint or concern, you have the right to make a complaint to the Information Commissioner's Office using their website <a href="http://www.ico.co.uk/" target="_blank">www.ico.co.uk</a> or with a local authority.</p>
    <p>We may modify or update this Privacy Notice from time to time. Where changes to this Privacy Notice will have a fundamental impact on the nature of the processing or otherwise have a substantial impact on individuals, we will give you sufficient advance notice so that you have the opportunity to exercise your rights (e.g. to object to the processing).</p>
  </div>
</template>

<script>
export default {
  name: 'PrizeSaverPrivacyPolicy',
  metaInfo() {
    return {
      title: 'Prize Saver | Privacy Policy | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
